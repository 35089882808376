<template>
  <layout-vertical>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

    <my-page
      v-if="showMyPage"
      slot="customizer"
    />
    
    

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
// import MyPageVue
import MyPage from '../../views/apps/my-page/MyPage.vue'
export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    MyPage
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      showMyPage: $themeConfig.layout.myPage
    }
  },
}
</script>
