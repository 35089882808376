<template>
    <div
      class="customizer d-none d-md-block"
      :class="{'open': isMyPageOpen}"
    >
      <!-- Toggler -->
      <b-link
        class="customizer-toggle block mt-5"
        @click="isMyPageOpen = !isMyPageOpen"
      >
      <feather-icon
        icon="UserIcon"
        size="15"
      />
        
      </b-link>
      
      <!-- /Toggler -->
  
      <!-- Header -->
      <div class="customizer-section d-flex justify-content-between align-items-center">
        <div>
          <h4 class="text-uppercase mb-0 text-primary">
            Hello {{ name }}
          </h4>
          <small>Customize &amp; Preview in Real Time</small>
          <br/>
          <span class="mb-0 text-warning">Quick Statistics Feature is still in Beta, You Will not be able to view any Data yet</span>

        </div>
        <feather-icon
          icon="XIcon"
          size="18"
          class="cursor-pointer"
          @click="isMyPageOpen = !isMyPageOpen"
        />
      </div>
      <!-- Header -->
  
      
    </div>
  </template>
  
  <script>
  import {
    BLink, //BFormRadioGroup, BFormGroup, BFormCheckbox,
  } from 'bootstrap-vue'
//   import vSelect from 'vue-select'
//   import VuePerfectScrollbar from 'vue-perfect-scrollbar'
   import useMyPage from './useMyPage'
  
  export default {
    components: {
      // BSV
      BLink,
    //   BFormRadioGroup,
    //   BFormCheckbox,
    //   BFormGroup,
  
    //   // 3rd party
    //   vSelect,
    //   VuePerfectScrollbar,
  
    },
    setup() {
      const {
        // // Vertical Menu
        // isVerticalMenuCollapsed,
  
        // Customizer
        isMyPageOpen,
  
        // // Skin
        // skin,
        // skinOptions,
  
        // // Content Width
        // contentWidth,
        // contentWidthOptions,
  
        // // RTL
        // isRTL,
  
        // // routerTransition
        // routerTransition,
        // routerTransitionOptions,
  
        // // Layout Type
        // layoutType,
        // layoutTypeOptions,
  
        // // NavMenu Hidden
        // isNavMenuHidden,
  
        // // Navbar
        // navbarColors,
        // navbarTypes,
        // navbarBackgroundColor,
        // navbarType,
  
        // // Footer
        // footerTypes,
        // footerType,
      } = useMyPage()
  
    //   if (layoutType.value === 'horizontal') {
    //     // Remove semi-dark skin option in horizontal layout
    //     const skinSemiDarkIndex = skinOptions.findIndex(s => s.value === 'semi-dark')
    //     delete skinOptions[skinSemiDarkIndex]
  
    //     // Remove menu hidden radio in horizontal layout => As we already have switch for it
    //     const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
    //     delete navbarTypes[menuHiddneIndex]
    //   }
  
    //   // Perfect Scrollbar
    //   const perfectScrollbarSettings = {
    //     maxScrollbarLength: 60,
    //     wheelPropagation: false,
    //   }
  
      return {
        // Vertical Menu
        // isVerticalMenuCollapsed,
  
        // Customizer
        isMyPageOpen,
  
        // Skin
        // skin,
        // skinOptions,
  
        // // Content Width
        // contentWidth,
        // contentWidthOptions,
  
        // // RTL
        // isRTL,
  
        // // routerTransition
        // routerTransition,
        // routerTransitionOptions,
  
        // // Layout Type
        // layoutType,
        // layoutTypeOptions,
  
        // // NavMenu Hidden
        // isNavMenuHidden,
  
        // // Navbar
        // navbarColors,
        // navbarTypes,
        // navbarBackgroundColor,
        // navbarType,
  
        // // Footer
        // footerTypes,
        // footerType,
  
        // // Perfect Scrollbar
        // perfectScrollbarSettings,
      }
    },
    data:{
      return: {
        name: null
      }
    },
    mounted() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.name = userData.first_name + ' ' + userData.last_name
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  
  <style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';
  
  .customizer-section {
    padding: 1.5rem;
      border-bottom: 1px solid #ebe9f1;
  
    .dark-layout & {
      border-color: $theme-dark-border-color;
    }
  
    #skin-radio-group ::v-deep {
      .custom-control-inline {
        margin-right: 0.7rem;
      }
    }
  
    .form-group {
      margin-bottom: 1.5rem;;
      &:last-of-type {
      margin-bottom: 0;
      }
      ::v-deep legend {
        font-weight: 500;
      }
    }
  }
  
  .mark-active {
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
  }
  
  .ps-customizer-area {
    height: calc(100% - 83px)
  }
  </style>
  ./useMyPage